
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RecipePreview } from '@/models/Recipe';

@Component({
    name: 'RecipeSlider',
    components: {
    },
})
export default class RecipeSlider extends Vue {
    @Prop({ type: Array, required: true }) recipes: RecipePreview[];
    @Prop({ type: Boolean, required: false, default: true }) showDottedNav: boolean;
    @Prop({ type: Boolean, required: false, default: true }) showArrows: boolean;
    @Prop({ type: Boolean, required: false, default: true }) fullWidth: boolean;
    @Prop({ type: Boolean, required: false, default: false }) asSection: boolean;
}
