
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'VariantSelectorTextButton',
})
export default class VariantSelectorTextButton extends Vue {
    @Prop() private code: any;
    @Prop() private label: any;
    @Prop() private selected: any;
    @Prop() private expanded: boolean;
}
