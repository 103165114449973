import { ContactFormRequest, DealerSignupRequest, SignupResponse } from '@/models/Content';
import { NavigationItem, NavigationItemResponse } from '@/models/Navigation';
import axios, { AxiosResponse } from 'axios';

export default class ContentService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/content`;
    }

    public async getChildren(id: string = ''): Promise<NavigationItem[]> {
        try {
            const res: AxiosResponse<NavigationItemResponse> = await axios.get(`${this.endpoint}/children/${id}`);
            return res.data.children;
        } catch (e) {
            console.log(e);
        }
    }

    public async getChildrenWithParent(id: string = '__root__'): Promise<NavigationItemResponse> {
        if (id === '__root__') id = '';
        try {
            const res: AxiosResponse<NavigationItemResponse> = await axios.get(`${this.endpoint}/children/${id}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async getCustomContent(): Promise<NavigationItemResponse> {
        try {
            const res: AxiosResponse<NavigationItemResponse> = await axios.get(`${this.endpoint}/custom`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async sendContactRequest(payload: ContactFormRequest): Promise<boolean> {
        const data = new FormData();

        this.addFormData(data, 'contactrequest', JSON.stringify(payload));
        payload.fields.filter(field => field.type === 'upload').forEach(field => {
            field.files?.forEach(file => {
                this.addFormData(data, 'uploadFile', file.file, file.id);
            });
        });

        try {
            await axios({
                method: 'POST',
                url: `${this.endpoint}/contactrequest`,
                data,
            });          
            return true;
        } catch (e) {
            return false;
        }
    }

    public async sendNewCustomerRequest(payload: any): Promise<any> {
        try {
            return await axios.post(`${this.endpoint}/newcustomerrequest`, payload);
        } catch (e) {
            return false;
        }
    }

    private addFormData(formdata: FormData, key: string, value: any, filename: any = null) {
        if (filename) formdata.append(key, value as any, filename);
        else formdata.append(key, value as any);
    }

    public async sendDealerSignupRequest(payload: DealerSignupRequest): Promise<SignupResponse> {
        try {
            const res: AxiosResponse<SignupResponse> = await axios.post(`${this.endpoint}/dealersignup`, payload);
            return res.data;
        } catch (e) {
            return {
                completed: false,
            };
        }
    }

    public async sendMemberSignupRequest(payload: DealerSignupRequest): Promise<SignupResponse> {
        try {
            const res: AxiosResponse<SignupResponse> = await axios.post(`${this.endpoint}/membersignup`, payload);
            return res.data;
        } catch (e) {
            return {
                completed: false,
            };
        }
    }
}
