
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DisplayPriceInfo, PreviewVariant } from '@/models/Product';
import VariantSelectorTextButton from './product-details/VariantSelectorTextButton.vue';


@Component({
    name: 'AddToCartVariantSelector',
    components: {
        VariantSelectorTextButton,
    }
})
export default class AddToCartVariantSelector extends Vue {
    @Prop({ type: String, required: false, default: null }) portalPrefix: string;
    @Prop({ type: String, required: false, default: null }) sku: string;
    @Prop({ type: Array, required: false, default: null }) variants: PreviewVariant[];
    @Prop({ type: Boolean, required: false, default: true }) showPrices: boolean;
    @Prop({ type: String, required: false, default: null }) buttonText: string;
    @Prop({ type: String, required: false, default: null }) colliText: string;

    selectedVariant: PreviewVariant = null;
    quantity: number = 1;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get variantOptions(): PreviewVariant[] {
        return this.variants;
    }

    get price(): DisplayPriceInfo {
        return this.selectedVariant.price;
    }
    get unit(): string {
        return this.selectedVariant.unit;
    }
    get allowPurchase(): boolean {
        return this.selectedVariant.allowpurchase;
    }

    selectVariant(variant: PreviewVariant)
    {
        this.quantity = variant.colli;
        this.selectedVariant = variant;
    }

    isOutOfStock(variant: PreviewVariant): boolean {
        return variant.stockvalue <= 0;
    }

    private created() {
        if (this.variants.length > 0)
            this.selectVariant(this.variants[0]);
    }
}
