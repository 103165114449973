export default function () {

    document.addEventListener('click', function (e) {

        if (!e.target.parentElement?.classList.contains('urt-content-list-filter'))
            return;

        const tagFilter = e.target.dataset.tagFilter;

        Array.from(e.target.parentElement.getElementsByTagName('button')).forEach(element => { element.classList.remove('active'); });
        if (tagFilter)
            e.target.classList.add('active');

        Array.from(e.target.closest('.urt-content-list').getElementsByClassName('article')).forEach(element => {
            if (!tagFilter)
                element.style.display = null;
            else if (!element.dataset.tags.toLowerCase().split(' ').includes(tagFilter.toLowerCase()))
                element.style.display = 'none';
            else
                element.style.display = null;
        });

    }, false);
}