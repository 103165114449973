
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CartModule from '../store/modules/CartModule';
import LoadingModule from '../store/modules/LoadingModule';
import { AddOrUpdateCartEditModel, WebCartItem } from '../models/Cart';
import { StockMessage, WebProduct } from '../models/Product';
import AddToCartStock from './AddToCartStock.vue';
import TrackingService from '../services/TrackingService';
import { copy } from '@/utils/objectUtils';

const trackingService: TrackingService = new TrackingService();

@Component({
    name: 'AddToCartButtonStatic',
    components: {
        AddToCartStock
    }
})
export default class AddToCartButtonStatic extends Vue {
    @Prop({ type: Object, required: false, default: null }) product: WebProduct;
    @Prop({ type: String, required: false, default: '' }) sku: string;
    @Prop({ type: String, required: false, default: '' }) variantid: string;
    @Prop({ type: String, required: false, default: '' }) unit: string;
    @Prop({ type: Number, required: false, default: 1 }) quantity: number;
    @Prop({ type: Number, required: false, default: 1 }) colli: number;
    @Prop({ type: Object, required: false, default: null }) stockMessage: StockMessage;
    @Prop({ type: Boolean, required: false, default: true }) displayModal: boolean;
    @Prop({ type: Boolean, required: false, default: true }) displayPreview: boolean;
    @Prop({ type: String, required: false, default: 'default' }) size: string;
    @Prop({ type: Array, required: false }) private addonProducts: string[];
    @Prop({ type: String, required: false, default: null }) buttonText: string;
    @Prop({ type: String, required: false, default: null }) colliText: string;

    model: number = null;

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get translationsReady(): boolean {
        return (this.buttonText != null && this.colliText != null) || this.$root.$data.translationsLoaded === true;
    }

    get fallbackButtonText(): any {
        return this.buttonText ?? this.$t('product.add-to-cart', ['Buy now']);
    }

    get fallbackColliText(): any {
        return this.colliText ?? this.$t('product.colli-notice', [this.colli, this.unit, 'Sold in packages of {0} {1}']);
    }

    get sizeClasses(): any {
        const retval: any = {
            input: '',
            button: '',
            iconRatio: '.8',
        };
        if (this.size !== 'medium') {
            retval.input = `uk-form-${this.size}`;
            retval.button = `uk-button-${this.size}`;
        }
        if (this.size === 'small') {
            retval.iconRatio = '.6';
        }
        if (this.size === 'large') {
            retval.iconRatio = '1.1';
        }

        return retval;
    }

    get productId(): string {
        if (this.product) {
            this.colli = this.product.colli;
            this.unit = this.product.unit;
            this.stockMessage = this.product.stockmessage;
            return this.product.id;
        } else if (this.sku) {
            return this.sku;
        }
        return 'SKU_MISSING';
    }

    get itemFromLookup(): WebCartItem {

        const variantid = this.variantid;
        
        return CartModule.CART_LOOKUP[`${this.productId}###${variantid ?? ''}`];
    }

    get enableAddedToCartModal(): boolean {
        return (window as any).CbxEnableAddedToCartModal;
    }

    get payload(): AddOrUpdateCartEditModel {
        return {
            productid: this.productId,
            quantity: this.quantity,
            variantid: this.variantid,
            addonproductids: this.addonProducts ?? [],
        };
    }

    private isNumber(event: any) {
        return event.charCode >= 48 && event.charCode <= 57;
    }

    private async addOrUpdate() {
        if (this.isLoading && this.itemFromLookup)
            return false;

        let itemBeforeChange: WebCartItem = null;
        let quantityBeforeChange: number = 0;
        if (this.itemFromLookup) {
            itemBeforeChange = copy(this.itemFromLookup);
            quantityBeforeChange = itemBeforeChange.quantity;
        }

        // respect colli 
        this.payload.quantity = Math.ceil((this.model + quantityBeforeChange) / this.colli) * this.colli;

        await CartModule.ADD_TO_CART(this.payload);

        // track add to cart
        if (itemBeforeChange)
            trackingService.trackAddToCart({ ...copy(this.itemFromLookup), quantity: Math.ceil(this.model / this.colli) * this.colli });
        else
            trackingService.trackAddToCart(this.itemFromLookup); 

        if (this.enableAddedToCartModal && this.displayModal) {
            
            const variantid = this.variantid;

            CartModule.SET_ADD_TO_CART_MODAL({
                show: true,
                productId: this.sku || this.product.id,
                variantId: variantid
            });
        } else if (this.displayPreview) {
            CartModule.SET_IS_OPEN(true);
        }

        this.model = this.quantity;
    }

    private async increment() {
        this.model += this.colli;
    }

    private async decrement() {
        this.model -= this.colli;
    }

    private created() {
        this.model = this.quantity;
    }

    @Watch("productId")
    private onProductIdChange() {
        this.model = this.quantity;
    }
}
